import styled from "styled-components";
import { useRouter } from "next/router";
import Image from "next/image";

import { ButtonDownload } from "../Button";

import { ReactComponent as GooglePlay } from "../../../assets/icons/googlePlay.svg";
import { ReactComponent as AppStore } from "../../../assets/icons/appStore.svg";

import HeaderImg from "../../../assets/images/headerImg.png";
import HeaderImg2 from "../../../assets/images/headerImg2.png";
import RedCircle from "../../../assets/icons/red-circle.svg";
import YellowCircle from "../../../assets/icons/yellow-circle.svg";
import Circle from "../../../assets/icons/circle.svg";

const ButtonsContainer = styled.div`
  max-width: 500px;
`;

const Title = styled.h1`
  font-weight: 400;
  font-size: 47px;
  color: ${(props) => props.color};

  @media (max-width: 570px) {
    font-size: 55px;
    line-height: 65px;
  }
`;
const Text = styled.p`
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  color: #d7daeb;
  @media (max-width: 570px) {
    font-size: 30px;
  }
`;

export default function Header() {
  const router = useRouter();
  return (
    <>
      <div
        className="row m-0 p-1 p-md-5 d-flex justify-content-center"
        style={{
          background: router.pathname == "/bulk-order" && "#2E2F55",
          minHeight: 100,
        }}
      >
        <div className="col-12 col-xl-5 d-flex flex-column justify-content-evenly align-items-center py-5">
          <RedCircle
            style={{
              position: "absolute",
              left: "24%",
              top: "17%",
              backgroundSize: "10px 10px",
            }}
          />
          <YellowCircle
            style={{ position: "absolute", left: "50%", top: "4%" }}
          />
          <Title color={router.pathname == "/" ?  "#2E2F55" :  "#FFFFFF"}>
            {router.pathname !== "/"
              ? "We create your product line "
              : "Make your own clothes"}
          </Title>
          <Text className="h6 my-5s">
            {router.pathname == "/"
              ? "Makesy designs, samples & produces for you. "
              : "Join the revolution on fast fashion and order from artisans near you."}
          </Text>
          <ButtonsContainer className="col-12 row m-0 p-0 d-xl-flex justify-content-center justify-content-xl-start">
            <ButtonDownload
              className="col-12 col-sm-5  mx-3 mx-md-0  me-md-4"
              title="Get it on"
              subTitle="Google Play"
              dark={router.pathname == "/bulk-order"}
              icon={GooglePlay}
              link="https://play.google.com/store/apps/details?id=com.makesymobile"
            />
            <ButtonDownload
              className="col-12 col-sm-5"
              title="Download on the"
              subTitle="App Store"
              dark={router.pathname == "/bulk-order"}
              icon={AppStore}
              link="https://apps.apple.com/jo/app/makesy/id1559418686"
            />
          </ButtonsContainer>
          <Circle style={{ position: "absolute", left: "15%", bottom: "0" }} />
        </div>
        <div className="col-12 col-xl-5 d-xxl-flex justify-content-center">
          <Image src={router.pathname == "/" ? HeaderImg : HeaderImg2} alt="header Image" priority />
        </div>
      </div>
    </>
  );
}
